import { createApp } from "vue";
import App from "./App.vue";
import VueMq from "vue3-mq";
import router from "./router";
import "@lottiefiles/lottie-player";

import "./assets/tailwind.css";
import "./assets/style.scss";

import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);

const app = createApp(App).use(router);

app.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 768,
    md: 1024,
    lg: 1280,
    xl: Infinity
  },
  defaultBreakpoint: "lg"
});

app.mount("#app");
