<template>
  <div
    class="grid grid-rows-2 grid-cols-2 fixed top-0 burger-menu relative font-medium text-lg"
  >
    <div
      class="flex items-center justify-center burger-menu__item"
      @click="$emit('navigate', 'home')"
    >
      Hola 👋
    </div>

    <div
      class="flex items-center justify-center burger-menu__item"
      @click="$emit('navigate', 'bodas')"
    >
      Bodas
    </div>

    <div
      class="flex items-center justify-center burger-menu__item"
      @click="$emit('navigate', 'eventos')"
    >
      Eventos corporativos
    </div>

    <div
      class="flex items-center justify-center burger-menu__item"
      @click="$emit('navigate', 'fiestas')"
    >
      Fiestas privadas
    </div>

    <img
      src="../assets/img/close-black.svg"
      alt="close"
      class="burger-menu__close"
      @click="$emit('close')"
    />
  </div>
</template>

<style lang="scss" scoped>
.burger-menu {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: white;
  overflow: hidden;

  &__close {
    position: absolute;
    top: 52px;
    right: 24px;
  }

  &__item {
    text-align: center;

    &:nth-child(1) {
      background-color: theme("colors.white");
    }

    &:nth-child(2) {
      background-color: theme("colors.pink");
    }

    &:nth-child(3) {
      background-color: theme("colors.red");
      color: white;
    }

    &:nth-child(4) {
      background-color: theme("colors.gold");
    }
  }
}
</style>
