<template>
  <footer
    class="m-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding mb-0"
  >
    <div class="lg:flex">
      <div
        class="flex flex-col lg:w-full lg:justify-between items-center lg:items-start mt-xlpadding lg:mt-0"
      >
        <img :src="locationIcon" style="height: 32px; width: 32px" />

        <div
          class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 text-center lg:text-left font-medium mt-4"
          style="max-width: 320px"
        >
          Nuestra sede está en Mallorca, pero soñamos en todas partes.
        </div>
      </div>
      <div
        class="flex flex-col lg:w-full lg:justify-start items-center lg:items-end mt-padding lg:mt-0"
      >
        <span class="text-sm text-right">Síguenos en: </span>
        <div
          class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium text-center lg:text-right mt-padding lg:mt-4"
        >
          <a
            href="https://instagram.com/bendita.jarana?igshid=1rncn8nq6w75v"
            class="block underline"
            >Instagram</a
          >
          <a
            href="https://m.facebook.com/benditajarana.eventos/"
            class="block underline"
            >Facebook</a
          >
        </div>
      </div>
    </div>

    <div
      class="flex flex-col lg:flex-row lg:w-full lg:justify-between items-center mt-padding xl:mt-xlpadding text-small"
    >
      <span> Copyright Bendita Jarana 2020© </span>
      <a href="mailto:info@benditajarana.com" class="cursor-pointer underline"
        >info@benditajarana.com</a
      >
      <span @click="showPrivacyForm = true" class="cursor-pointer underline"
        >Política de privacidad</span
      >
    </div>
    <privacy-form v-if="showPrivacyForm" @close="showPrivacyForm = false" />
  </footer>
</template>

<script>
import PrivacyForm from "./privacy/PrivacyForm.vue";

export default {
  components: {
    PrivacyForm
  },
  data() {
    return {
      showPrivacyForm: false
    };
  },
  computed: {
    locationIcon() {
      const isDarkBg = this.$route.name?.includes("event");
      return require(`../assets/img/${
        isDarkBg ? "location-white.svg" : "location-black.svg"
      }`);
    }
  }
};
</script>

<style lang="scss" scoped></style>
