<template>
  <div
    class="contact m-padding md:m-mdpadding lg:m-lgpadding flex flex-col items-center justify-center"
  >
    <lottie-player
      background="transparent"
      speed="1"
      style="width: 148px; height: 148px; transform: rotate(180deg)"
      :src="
        require(`../assets/animations/${
          isDarkBg ? 'lottie-hand-point--white.json' : 'lottie-hand-point.json'
        }`)
      "
      autoplay
      loop
    ></lottie-player>

    <h1
      class="font-title italic text-title lg:text-lgtitle leading-tight mt-padding lg:mt-lgpadding text-center lg:max-w-block"
    >
      ¿Quieres vivir una bendita Jarana?
    </h1>

    <div
      @click="toggleForm(true)"
      class="flex justify-between items-center border-t border-b w-full lg:w-block py-4 m-8 cursor-pointer lg:mb-lgpadding"
    >
      <span class="font-medium text-h3 leading-h3">Si quiero 😉</span>
      <img
        :src="
          require(`../assets/img/${
            isDarkBg ? 'arrow-white.svg' : 'arrow-black.svg'
          }`)
        "
        alt="arrow black"
      />
    </div>

    <lottie-player
      background="transparent"
      speed="1"
      style="width: 148px; height: 148px"
      :src="
        require(`../assets/animations/${
          isDarkBg ? 'lottie-hand-point--white.json' : 'lottie-hand-point.json'
        }`)
      "
      autoplay
      loop
    ></lottie-player>

    <teleport to="body">
      <contact-form v-if="showContactForm" @close="toggleForm(false)" />
    </teleport>
  </div>
</template>

<script>
import ContactForm from "./contact/ContactForm";

export default {
  components: {
    ContactForm,
  },
  data() {
    return {
      showContactForm: false,
    };
  },
  computed: {
    isDarkBg() {
      return (
        this.$route.name === "eventos" || this.$route.name === "evento-details"
      );
    },
  },
  methods: {
    toggleForm(open) {
      this.showContactForm = open;
      if (this.showContactForm) {
        document.body.classList.add("fixed");
      } else {
        document.body.classList.remove("fixed");
      }
    },
  },
};
</script>
