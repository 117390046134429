<template>
  <div class="divider" :class="{ 'divider--white': white }"></div>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 1px solid #00000033;
  margin: 24px 0;
  width: 100%;
  height: 1px;

  &--white {
    border-bottom: 1px solid #ffffff33;
  }
}
</style>
