<template>
  <div class="max-w-content mx-auto my-4">
    <h2 class="text-5xl font-title mb-8 italic">¡Gracias!</h2>
    <p class="max-w-blockm mb-4">
      Hemos recibido tus respuestas y en breve nos pondremos en contacto
      contigo.
    </p>
    <p class="max-w-blockm mb-4">
      Para que la espera sea más corta tenemos un regalo para ti, ¡Ábrelo! 🎁
    </p>

    <a
      :href="
        $mq === 'xs'
          ? '/BenditaJarana_Wallpaper_Mobile.jpeg'
          : '/BenditaJarana_Wallpaper_Desktop.jpeg'
      "
      download="BenditaJarana_Wallpaper.jpeg"
      class="font-medium text-lg flex items-center"
    >
      Quiero mi regalo
      <img src="@/assets/img/gift.png" width="40" class="ml-4" alt="gift" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  height: 35px;

  @apply text-xl;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}
</style>
