<template>
  <div class="bendita">
    <div class="relative xl:hidden">
      <divider class="absolute top-0" :white="isDarkBg" />

      <div
        style="z-index: 10"
        class="fixed w-full px-padding md:px-mdpadding lg:px-lgpadding flex justify-between items-center bg-transparent h-topmenu pt-4"
      >
        <router-link :to="{ name: 'home' }">
          <img
            :src="
              require(`./assets/img/${
                isDarkBg ? 'logo-white.png' : 'logo.png'
              }`)
            "
            :srcset="`
        ${require(`./assets/img/${isDarkBg ? 'logo-white.png' : 'logo.png'}`)},
        ${require(`./assets/img/${
          isDarkBg ? 'logo-white@2x.png' : 'logo@2x.png'
        }`)} 2x,
        ${require(`./assets/img/${
          isDarkBg ? 'logo-white@3x.png' : 'logo@3x.png'
        }`)} 3x `"
            style="height: 72px; width: 72px"
          />
        </router-link>

        <img
          :src="
            require(`./assets/img/${
              isDarkBg ? 'menu-white.svg' : 'menu-black.svg'
            }`)
          "
          alt="menu"
          @click="toggleMenu(true)"
        />
      </div>

      <burger
        v-show="showMenu"
        @close="toggleMenu(false)"
        @navigate="goToSection"
      />

      <router-view></router-view>
    </div>

    <div
      class="bendita__cell"
      :class="{ 'bendita__cell--active': section === code }"
      v-for="({ code, label }, index) in sections"
      :key="code"
    >
      <transition name="slide-fade" mode="out-in">
        <div
          v-if="section !== code"
          @click="goToSection(code)"
          class="bendita__link"
          key="menu"
        >
          <template v-if="index === 0">
            <img
              src="./assets/img/logo-small.png"
              alt="logo"
              style="margin-top: -20px"
              :srcset="`
                ${require('./assets/img/logo-small.png')},
                ${require('./assets/img/logo-small@2x.png')} 2x,
                ${require('./assets/img/logo-small@3x.png')} 3x
              `"
            />
          </template>
          <template v-else>
            <div class="text-small" style="writing-mode: horizontal-tb">
              {{ index }}
            </div>
          </template>

          <div class="text-h3 leading-h3 font-medium">
            {{ label }}
          </div>
          <span> &nbsp; </span>
        </div>

        <router-view v-else key="content"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Divider from "./components/Divider";
import Burger from "./components/Burger";

export default {
  name: "App",
  components: {
    Divider,
    Burger,
  },
  data() {
    return {
      section: "home",
      showMenu: false,
    };
  },
  computed: {
    isDarkBg() {
      return this.$route.name?.includes("evento");
    },
  },
  created() {
    this.sections = [
      {
        code: "home",
        label: "",
      },
      {
        code: "bodas",
        label: "Bodas",
      },
      {
        code: "eventos",
        label: "Eventos corporativos",
      },
      {
        code: "fiestas",
        label: "Fiestas privada",
      },
    ];
  },
  watch: {
    "$route.name": {
      immediate: true,
      handler(name) {
        let resolvedName = name;
        if (!name) {
          return;
        }

        if (name.includes("boda")) {
          resolvedName = "bodas";
        }

        if (name.includes("evento")) {
          resolvedName = "eventos";
        }

        if (name.includes("fiesta")) {
          resolvedName = "fiestas";
        }

        this.section = resolvedName;
      },
    },
  },
  methods: {
    toggleMenu(open) {
      this.showMenu = open;
      if (this.showMenu) {
        document.body.classList.add("fixed");
      } else {
        document.body.classList.remove("fixed");
      }
    },
    goToSection(name) {
      this.toggleMenu(false);
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Epilogue, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
  font-size: 16px;

  @media (min-width: 1280px) {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
}

body {
  margin: 0;
}

.bendita {
  display: flex;
  width: 100%;
  height: 100%;

  --menu-entry-width: 66px;

  &__link {
    width: var(--menu-entry-width);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    writing-mode: vertical-lr;
    padding: 66px 6px;
    cursor: pointer;
  }

  &__cell {
    position: relative;
    display: flex;
    width: var(--menu-entry-width);
    align-items: center;
    justify-content: center;
    transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
    will-change: "width";

    @media (max-width: 1279px) {
      display: none;
    }

    &:nth-child(2) {
      background-color: theme("colors.white");
    }

    &:nth-child(3) {
      background-color: theme("colors.pink");
    }

    &:nth-child(4) {
      background-color: theme("colors.red");
      color: white;
    }

    &:nth-child(5) {
      background-color: theme("colors.gold");
    }

    &--active {
      width: calc(100% - 3 * var(--menu-entry-width)); // 66px * 3
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s linear;
}
.slide-fade-leave-active {
  transition: all 0.1s linear;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
