<template>
  <section class="home relative pt-topmenu xl:pt-0">
    <div class="hidden xl:block">
      <divider />

      <!-- Header -->
      <div
        class="mx-xlpadding xxl:mx-xxlpadding flex justify-end mb-10"
        style="height: 120px"
      >
        <img
          :src="require('../assets/img/logo.png')"
          :srcset="`
          ${require('../assets/img/logo.png')},
          ${require('../assets/img/logo@2x.png')} 2x,
          ${require('../assets/img/logo@3x.png')} 3x
        `"
          class="fixed fixed-logo"
        />

        <span
          class="text-sm circled"
          style="width: 56px; height: 28px; margin-top: 12px"
        >
          Hola
        </span>
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end mb-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding mt-5 md:mt-10"
    >
      <h1
        class="flex-1 font-title italic text-title md:text-mdtitle lg:text-lgtitle xl:text-xltitle leading-title md:leading-mdtitle lg:leading-lgtitle xl:leading-xltitle md:max-w-block"
      >
        Somos la
        <span class="cursor-pointer underlined">alternativa</span>
        a las bodas y eventos convencionales.
      </h1>
      <span class="mt-5 md:pl-8 lg:mt-0 max-w-half" style="max-width: 350px">
        <strong>Bendita Jarana</strong> somos una Agencia de organización de
        Eventos, Bodas, Fiestas y Benditas Jaranas.
      </span>
    </div>

    <!-- Cover image -->

    <div class="w-full relative video-holder" v-show="!vimeoPlay">
      <video autoplay muted loop playsinline id="home-video">
        <source
          src="https://res.cloudinary.com/ds1kbqtyu/video/upload/v1606387695/bendita-jarana.mp4"
          type="video/mp4"
        />
      </video>
      <img
        src="@/assets/img/play-button.png"
        style="width: 120px"
        alt="play"
        @click="playVideo"
        class="absolute bottom-0 right-0"
      />
    </div>

    <div style="padding: 56.25% 0 0 0; position: relative" v-show="vimeoPlay">
      <iframe
        src="https://player.vimeo.com/video/477550534?color=f9cbc9&title=0&byline=0&portrait=0"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
    <!-- How we do it -->
    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end my-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding"
    >
      <div class="md:w-half">
        <span
          class="font-title italic text-title md:text-mdtitle lg:text-lgtitle xl:text-xltitle leading-title md:leading-mdtitle lg:leading-lgtitle xl:leading-xltitle md:max-w-half lg:max-w-block"
        >
          Así somos, así lo hacemos.
        </span>
      </div>

      <div class="md:w-half md:pl-8 mt-5 lg:mt-0">
        <span
          class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium"
        >
          Son los valores con los que contruimos cada proyecto los que nos
          ayudan a crecer juntos.
        </span>
      </div>
    </div>

    <!-- Cards -->
    <div
      class="hidden! xl:grid! cards-grid m-xlpadding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:m-xxlpadding"
    >
      <card
        v-for="card in cards"
        :key="card.title"
        @mouseover="toggleLottie(`lottie-${card.title}`, true)"
        @mouseout="toggleLottie(`lottie-${card.title}`, false)"
      >
        <template #image>
          <lottie-player
            :ref="`lottie-${card.title}`"
            background="transparent"
            speed="1"
            style="width: 148px; height: 148px"
            hover
            :src="card.lottie"
            loop
          ></lottie-player>
        </template>
        <template #title>{{ card.title }}</template>
        <template #subtitle>{{ card.subtitle }}</template>
        <template #text>{{ card.text }}</template>
      </card>
    </div>
    <div class="slider-wrap xl:hidden">
      <swiper
        slides-per-view="auto"
        :centered-slides="$mq === 'xs'"
        :space-between="10"
        id="values-swiper"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="card in cards" :key="card.title" class="card-size">
          <card class="mx-auto">
            <template #image>
              <lottie-player
                background="transparent"
                speed="1"
                style="width: 148px; height: 148px"
                :src="card.lottie"
                autoplay
                loop
              ></lottie-player>
            </template>
            <template #title>{{ card.title }}</template>
            <template #subtitle>{{ card.subtitle }}</template>
            <template #text>{{ card.text }}</template>
          </card>
        </swiper-slide>
      </swiper>
    </div>
    <divider />

    <!-- Services -->
    <div
      class="flex flex-col lg:flex-row m-padding md:m-mdpadding lg:m-lgpadding xl:m-xlpadding xxl:m-xxlpadding"
    >
      <div
        class="font-title italic text-title md:text-mdtitle lg:text-lgtitle xl:text-xltitle leading-title md:leading-mdtitle lg:leading-lgtitle xl:leading-xltitle"
      >
        Cuestiona lo establecido.<br />
        <span class="cursor-pointer circled circled--big"> Celébralo</span>
        <template v-if="$mq === 'xs'"><br /> </template>
        a tu manera.
      </div>
    </div>

    <div
      class="cards-grid m-padding md:m-mdpadding xl:m-xlpadding xxl:m-xxlpadding hidden! xl:grid!"
    >
      <router-link
        :to="{ name: service.link }"
        v-for="service in services"
        :key="service.link"
        class="relative card-size service-card"
        style="margin: 0 auto"
        :class="{
          'service-card--bodas': service.link === 'bodas',
          'service-card--eventos': service.link === 'eventos',
          'service-card--fiestas': service.link === 'fiestas',
        }"
      >
        <div
          class="flex justify-between absolute bottom-0 px-10 w-full items-center font-medium"
          style="margin-bottom: 36px"
        >
          <span class="text-h3 leading-h3">{{ service.text }}</span>
          <img
            :src="require(`../assets/img/${service.linkImg}`)"
            :alt="service.text"
          />
        </div>
      </router-link>
    </div>
    <div class="slider-wrap xl:hidden">
      <swiper
        id="services-swiper"
        slides-per-view="auto"
        :centered-slides="$mq === 'xs'"
        :space-between="10"
        :pagination="{ clickable: true }"
      >
        <swiper-slide
          v-for="service in services"
          :key="service.link"
          class="card-size"
        >
          <div
            class="relative card-size service-card"
            :class="{
              'service-card--bodas': service.link === 'bodas',
              'service-card--eventos': service.link === 'eventos',
              'service-card--fiestas': service.link === 'fiestas',
            }"
            @click="$router.push({ name: service.link })"
          >
            <div
              class="flex justify-between absolute bottom-0 px-10 w-full items-center font-medium"
              style="margin-bottom: 36px"
            >
              <span class="text-h3 leading-h3">{{ service.text }}</span>
              <img
                :src="require(`../assets/img/${service.linkImg}`)"
                :alt="service.text"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div
      class="flex flex-col md:flex-row m-padding md:m-mdpadding lg:m-lgpadding xl:m-xlpadding xxl:m-xxlpadding md:justify-between my-xlpadding xl:my-0"
    >
      <div
        class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium flex-1"
      >
        <div class="lg:max-w-blockm md:max-w-blockm">
          Desde Bendita Jarana tenemos el objetivo de romper lo establecido y
          revolucionar este mercado.
        </div>
      </div>
      <div class="lg:flex lg:flex-wrap md:w-half mt-padding md:mt-0 md:pl-8">
        <div class="flex-1 lg:pr-4">
          <p>
            No nos gustan los protocolos, estereotipos, tendencias o prejuicios
            de ningún tipo.
          </p>
          <p class="mt-4">
            Bendita Jarana es querer romper con la norma. Es tener carácter
            propio para celebrar a tu manera.
          </p>
        </div>
        <div class="flex-1 lg:pl-4 mt-4 lg:mt-0">
          <p>
            Queremos que éste sea tu lugar para dejarte llevar, imaginar lo que
            quieres vivir y no preocuparte por nada más.
          </p>
          <p class="mt-4">
            Hemos nacido para ayudarte a crear experiencias memorables.
          </p>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <divider />
    <contact-section />

    <!-- Footer -->
    <JFooter />
    <divider />
  </section>
</template>

<script>
import Divider from "../components/Divider";
import Card from "../components/Card";
import ContactSection from "../components/ContactSection";
import JFooter from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "Home",
  components: {
    Divider,
    ContactSection,
    JFooter,
    Card,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      vimeoPlay: false,
    };
  },
  created() {
    this.cards = [
      {
        img: "hand-rock",
        title: "Rebeldía",
        subtitle: "Romper la norma.",
        lottie: require(`../assets/animations/lottie-hand-rock.json`),
        text:
          "Bendita Jarana tiene una personalidad rebelde que cuestiona lo establecido socialmente en el mundo de las bodas y eventos.",
      },
      {
        img: "hand-heart",
        title: "Corazón",
        subtitle: "Las personas lo primero.",
        lottie: require(`../assets/animations/lottie-hand-heart.json`),
        text:
          "Bendita Jarana la construyen las personas y lo que viven en cada celebración. Compartir estos recuerdos con ellas es el mejor regalo.",
      },
      {
        img: "hand-point",
        title: "Creatividad",
        subtitle: "Espacio para experimentar.",
        lottie: require(`../assets/animations/lottie-hand-point.json`),
        text:
          "Bendita Jarana fomenta la creatividad, la imaginación y la diversidad. Una experiencia Bendita Jarana es elegir lo que se quiere vivir.",
      },
    ];

    this.services = [
      {
        img: "service-bodas",
        linkImg: "arrow-black.svg",
        link: "bodas",
        text: "Bodas",
      },
      {
        img: "service-eventos",
        linkImg: "arrow-white.svg",
        link: "eventos",
        text: "Eventos",
      },
      {
        img: "service-fiestas",
        linkImg: "arrow-black.svg",
        link: "fiestas",
        text: "Fiestas",
      },
    ];
  },
  mounted() {},
  methods: {
    toggleLottie(ref, play) {
      const el = this.$refs[ref];
      if (play) {
        el.play();
      } else {
        el.stop();
      }
    },

    playVideo() {
      this.vimeoPlay = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-card {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  will-change: transform;
  transition: all 0.4s ease-out;

  @media (min-width: 1279px) {
    &:hover {
      transform: scale(1.1);
    }
  }

  &--bodas {
    background-image: url("../assets/pictures/service-bodas.jpg");

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("../assets/pictures/service-bodas@2x.jpg");
    }

    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url("../assets/pictures/service-bodas@3x.jpg");
    }
  }

  &--eventos {
    color: white;
    background-image: url("../assets/pictures/service-eventos.jpg");

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("../assets/pictures/service-eventos@2x.jpg");
    }

    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url("../assets/pictures/service-eventos@3x.jpg");
    }
  }

  &--fiestas {
    background-image: url("../assets/pictures/service-fiestas.jpg");

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("../assets/pictures/service-fiestas@2x.jpg");
    }

    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url("../assets/pictures/service-fiestas@3x.jpg");
    }
  }
}

.fixed-logo {
  height: 120px;
  width: 120px;
  z-index: 99 !important;
  left: 24px;

  @media (min-width: 1024px) {
    left: 40px;
  }

  @media (min-width: 1336px) {
    left: 66px;
  }
}

.video-holder {
  @media (max-width: 727px) {
    height: 433px;
    width: 100vw;
    overflow: hidden;

    video {
      height: 433px;
      margin-left: -50%;
      max-width: inherit;
    }
  }
}
</style>
