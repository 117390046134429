<template>
  <div>
    <h2 class="font-medium text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2">
      II. Aceptación y cambios en esta política de privacidad
    </h2>
    <p>
      Es necesario que el Usuario haya leído y esté conforme con las condiciones
      sobre la protección de datos de carácter personal contenidas en esta
      Política de Privacidad y de Cookies, así como que acepte el tratamiento de
      sus datos personales para que el Responsable del tratamiento pueda
      proceder al mismo en la forma, durante los plazos y para las finalidades
      indicadas. El uso del Sitio Web implicará la aceptación de la Política de
      Privacidad y de Cookies del mismo.
    </p>
    <p>
      Bendita Jarana se reserva el derecho a modificar su Política de Privacidad
      y de Cookies, de acuerdo a su propio criterio, o motivado por un cambio
      legislativo, jurisprudencial o doctrinal de la Agencia Española de
      Protección de Datos. Los cambios o actualizaciones de esta Política de
      Privacidad y de Cookies serán notificados de forma explícita al Usuario.
    </p>
    <p>
      Esta Política de Privacidad y de Cookies fue actualizada el día 17 de
      diciembre 2020 para adaptarse al Reglamento (UE) 2016/679 del Parlamento
      Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de
      las personas físicas en lo que respecta al tratamiento de datos personales
      y a la libre circulación de estos datos (RGPD) y a la Ley Orgánica 3/2018,
      de 5 de diciembre, de Protección de Datos Personales y garantía de los
      derechos digitales.
    </p>
  </div>
</template>

<style lang="scss" scoped>
h3 {
  font-weight: 600 !important;
  margin-top: 24px;
}
p {
  margin: 12px 0 24px 0;
}

ul {
  list-style: circle;
  padding-left: 24px;
}
</style>
