<template>
  <div class="card">
    <div class="h-half flex items-center justify-center">
      <slot name="image"></slot>
    </div>
    <div class="h-half px-8 pt-4">
      <h3 class="font-medium text-h3 leading-h3"><slot name="title"></slot></h3>
      <h4 class="font-medium mb-4"><slot name="subtitle"></slot></h4>
      <p><slot name="text"></slot></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  background-image: url("../assets/img/card-bg-grey.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height: 482px;
  width: 308px;

  @media (max-width: 728px) {
    height: 520px;
    width: 332px;
  }

  @media (min-width: 1279px) {
    height: 520px;
    width: 332px;
  }
}
</style>
