<template>
  <div class="max-w-content mx-auto my-4">
    <div
      class="text-right flex items-center text-sm cursor-pointer mb-6"
      @click="$emit('back')"
    >
      <img
        src="@/assets/img/back-black.svg"
        alt="back"
        class="mr-2"
        style="width: 24px; height: 24px"
      />
      3 de 4
    </div>
    <h3 class="text-medium text-lg">
      Dinos también tu email. No te preocupes, no enviamos Spam, tampoco nos
      gustan 😅
    </h3>

    <div class="relative mt-10">
      <input
        type="email"
        v-model="email"
        @keypress.enter="next"
        placeholder="Escribe tu email"
      />
      <template v-if="$mq !== 'xs'">
        <button @click="next">
          <img
            src="@/assets/img/next-black.png"
            style="width: 64px"
            alt="next"
            v-show="!!email"
            class="next-button"
          />
        </button>
      </template>
      <div
        class="text-right flex items-center w-full justify-end mt-2 text-sm"
        v-show="!!email"
      >
        <template v-if="$mq === 'xs'">
          <button @click="next">
            <img
              src="@/assets/img/next-black.png"
              style="width: 64px"
              alt="next"
            />
          </button>
        </template>
        <template v-else>
          Pulsa enter
          <img src="@/assets/img/enter-black.svg" alt="enter image" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    next() {
      this.$emit("next", this.email);
    },
  },
};
</script>

<style lang="scss" scoped></style>
