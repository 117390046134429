<template>
  <div class="max-w-content mx-auto my-4">
    <div
      class="text-right flex items-center text-sm cursor-pointer mb-6"
      @click="$emit('back')"
    >
      <img
        src="@/assets/img/back-black.svg"
        alt="back"
        class="mr-2"
        style="width: 24px; height: 24px"
      />
      1 de 4
    </div>
    <h3 class="text-medium text-lg">¿Cómo te llamas?</h3>

    <div class="relative mt-10">
      <input
        type="text"
        v-model="name"
        @keypress.enter="next"
        placeholder="Escribe tu nombre"
      />
      <template v-if="$mq !== 'xs'">
        <button @click="next" v-show="!!name">
          <img
            src="@/assets/img/next-black.png"
            style="width: 64px"
            alt="next"
            class="next-button"
          />
        </button>
      </template>
      <div
        class="text-right flex items-center w-full justify-end mt-2 text-sm"
        v-show="!!name"
      >
        <template v-if="$mq === 'xs'">
          <button @click="next">
            <img
              src="@/assets/img/next-black.png"
              style="width: 64px"
              alt="next"
            />
          </button>
        </template>
        <template v-else>
          Pulsa enter
          <img src="@/assets/img/enter-black.svg" alt="enter image" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
    };
  },
  mounted() {
    console.log(this.$mq);
  },
  methods: {
    next() {
      this.$emit("next", this.name);
    },
  },
};
</script>

<style lang="scss" scoped></style>
