<template>
  <div class="max-w-content mx-auto my-4">
    <div
      class="text-right flex items-center text-sm cursor-pointer mb-6"
      @click="$emit('back')"
    >
      <img
        src="@/assets/img/back-black.svg"
        alt="back"
        class="mr-2"
        style="width: 24px; height: 24px"
      />
      2 de 4
    </div>
    <h3 class="text-medium text-lg">
      ¿Cómo te imaginas celebrándolo? Cuéntanos un poco.
    </h3>

    <div class="relative mt-10">
      <textarea
        type="text"
        rows="3"
        v-model="idea"
        @keydown="inputHandler"
        placeholder="Escribe lo que imaginas"
      />
      <template v-if="$mq !== 'xs'">
        <button @click="next" v-show="!!idea">
          <img
            src="@/assets/img/next-black.png"
            style="width: 64px"
            alt="next"
            class="next-button"
          />
        </button>
      </template>
      <div class="flex justify-end lg:justify-between mt-2 text-sm">
        <template v-if="$mq === 'xs'">
          <button @click="next" v-show="!!idea">
            <img
              src="@/assets/img/next-black.png"
              style="width: 64px"
              alt="next"
            />
          </button>
        </template>
        <template v-else>
          <div>
            <strong>Tip!</strong> Pulsa Shift + Enter para hacer un salto de
            linea.
          </div>
          <div class="flex items-center" v-show="!!idea">
            Pulsa enter
            <img src="@/assets/img/enter-black.svg" alt="enter image" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idea: null,
    };
  },
  methods: {
    inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        // keycode 13 -> Enter
        e.preventDefault();
        this.next();
      }
    },
    next() {
      this.$emit("next", this.idea);
    },
  },
};
</script>

<style lang="scss" scoped></style>
