<template>
  <div class="max-w-content mx-auto my-4">
    <h2 class="text-title lg:text-lgtitle font-title italic">
      ¡Esto es un match!
    </h2>
    <p class="mt-4">
      Vamos a empezar a hablar sobre el tipo de Jarana que quieres montar. A ver
      si somos compatibles 😉
    </p>

    <div class="mt-10">
      <label class="container" @click="$emit('update:eventType', 'bodas')"
        >Nuestra Boda. Más nuestra que nunca.
        <input type="radio" name="radio" value="bodas" />
        <span class="checkmark"></span>
      </label>
      <label class="container" @click="$emit('update:eventType', 'eventos')"
        >Evento Corporativo. De los que marcan.
        <input type="radio" name="radio" value="eventos" />
        <span class="checkmark"></span>
      </label>
      <label class="container" @click="$emit('update:eventType', 'fiestas')"
        >Fiesta Privada. Va a ser bestial.
        <input type="radio" name="radio" value="fiestas" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 32px;
  cursor: pointer;
  height: 35px;
  font-weight: 500;

  @media (min-width: 1024px) {
    margin-bottom: 12px;

    @apply text-xl;
  }
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}
</style>
