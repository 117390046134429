<template>
  <section class="privacy-form">
    <divider class="mt-8" />
    <div
      class="relative m-padding md:m-mdpadding lg:m-lgpadding xxl:m-xlpadding"
    >
      <img
        src="@/assets/img/logo.png"
        :srcset="
          `
          ${require('@/assets/img/logo.png')},
          ${require('@/assets/img/logo@2x.png')} 2x,
          ${require('@/assets/img/logo@3x.png')} 3x
        `
        "
        class="privacy-form__logo"
      />

      <img
        src="@/assets/img/close.svg"
        alt="close"
        @click="$emit('close')"
        class="close"
        style="width: 48px"
      />
    </div>
    <div class="mx-padding md:mx-auto max-w-form xl:max-w-xlform">
      <h1
        class="text-title italic leading-title md:text-mdtitle md:leading-mdtitle lg:text-lgtitle lg:leading-lgtitle"
      >
        Política de privacidad
      </h1>
      <p class="mt-2">https://benditajarana.com</p>
      <privacy-block class="my-8" />
      <acceptance-block class="my-8" />
    </div>
  </section>
</template>

<script>
import Divider from "@/components/Divider";
import PrivacyBlock from "./PrivacyBlock";
import AcceptanceBlock from "./AcceptanceBlock";

export default {
  components: {
    Divider,
    AcceptanceBlock,
    PrivacyBlock
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.privacy-form {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: white;
  top: 0;
  left: 0;

  &__logo {
    height: 72px;
    width: 72px;

    @media (min-width: 1024px) {
      height: 120px;
      width: 120px;
    }
  }
}

.close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  will-change: transform;
  transition: transform 0.2s linear;
  height: 36px;
  width: 36px;

  @media (min-width: 1024px) {
    height: 72px;
    width: 72px;
  }

  &:hover {
    transform: rotate(-90deg);
  }
}
</style>
