<template>
  <section class="contact-form">
    <divider class="mt-8" />
    <div
      class="relative m-padding md:m-mdpadding lg:m-lgpadding xxl:m-xlpadding"
    >
      <img
        src="@/assets/img/logo.png"
        :srcset="
          `
          ${require('@/assets/img/logo.png')},
          ${require('@/assets/img/logo@2x.png')} 2x,
          ${require('@/assets/img/logo@3x.png')} 3x
        `
        "
        class="contact-form__logo"
      />

      <img
        src="@/assets/img/close.svg"
        alt="close"
        @click="close"
        class="close"
        style="width: 48px"
      />
    </div>
    <div class="mx-padding md:mx-auto max-w-form xl:max-w-xlform">
      <contact-event-type
        v-model:eventType="eventType"
        v-show="step === 'event'"
      />
      <contact-name
        @next="handleNameStep"
        @back="step = 'event'"
        v-show="step === 'name'"
      />
      <contact-idea
        @next="handleIdeaStep"
        @back="step = 'name'"
        v-show="step === 'idea'"
      />
      <contact-phone
        @next="handlePhoneStep"
        @back="step = 'idea'"
        v-show="step === 'phone'"
      />
      <contact-email
        @next="handleEmailStep"
        @back="step = 'phone'"
        v-show="step === 'email'"
      />
      <contact-thanks v-if="step === 'thanks'" />
    </div>
  </section>
</template>

<script>
import Divider from "@/components/Divider";
import ContactEventType from "./ContactEventType";
import ContactName from "./ContactName";
import ContactIdea from "./ContactIdea";
import ContactPhone from "./ContactPhone";
import ContactEmail from "./ContactEmail";
import ContactThanks from "./ContactThanks";

export default {
  components: {
    Divider,
    ContactName,
    ContactIdea,
    ContactPhone,
    ContactEmail,
    ContactThanks,
    ContactEventType
  },
  data() {
    return {
      eventType: null,
      name: null,
      idea: null,
      phone: null,
      email: null,
      step: "event"
    };
  },
  watch: {
    eventType() {
      this.step = "name";
    }
  },
  methods: {
    handleNameStep(name) {
      this.name = name;
      this.step = "idea";
    },
    handleIdeaStep(idea) {
      this.idea = idea;
      this.step = "phone";
    },
    handlePhoneStep(phone) {
      this.phone = phone;
      this.step = "email";
    },
    handleEmailStep(email) {
      this.email = email;
      this.submitForm();
      this.step = "thanks";
    },
    close() {
      this.name = null;
      this.idea = null;
      this.phone = null;
      this.email = null;
      this.eventType = null;
      this.$emit("close");
    },
    submitForm() {
      if (process.env.NODE_ENV !== "production") {
        return;
      }

      return fetch("https://formspree.io/f/mnqoooyr", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          eventType: this.eventType,
          idea: this.idea,
          phone: this.phone
        })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-form {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: white;
  top: 0;
  left: 0;

  &__logo {
    height: 72px;
    width: 72px;

    @media (min-width: 1024px) {
      height: 120px;
      width: 120px;
    }
  }
}

.close {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
  will-change: transform;
  transition: transform 0.2s linear;
  height: 36px;
  width: 36px;

  @media (min-width: 1024px) {
    height: 72px;
    width: 72px;
  }

  &:hover {
    transform: rotate(-90deg);
  }
}
</style>
