import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/eventos",
    name: "eventos",
    component: () =>
      import(/* webpackChunkName: "eventos" */ "../views/Eventos.vue")
  },
  {
    path: "/eventos/proyectos/:project",
    name: "evento-details",
    meta: {
      type: "evento"
    },
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/EventDetails.vue")
  },
  {
    path: "/bodas",
    name: "bodas",
    component: () =>
      import(/* webpackChunkName: "bodas" */ "../views/Bodas.vue")
  },
  {
    path: "/bodas/proyectos/:project",
    name: "boda-details",
    meta: {
      type: "boda"
    },
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/EventDetails.vue")
  },

  {
    path: "/fiestas",
    name: "fiestas",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Fiestas.vue")
  },
  {
    path: "/fiestas/proyectos/:project",
    name: "fiesta-details",
    meta: {
      type: "fiesta"
    },
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/EventDetails.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  }
});

export default router;
